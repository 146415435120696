<template>
  <b-card no-body>
    <b-overlay :show="isShow" rounded="lg" opacity="0.6">
      <b-card-body class="bv-example-row bv-example-row-flex-cols">
        <b-row>
          <b-col md="6">
            <b-badge variant="info">{{ pagination.from }} - {{ pagination.to }}</b-badge>
            <b-badge variant="warning">
              {{ pagination.per_page < pagination.total ? pagination.per_page : pagination.total }} /
              {{ pagination.total }}
            </b-badge>
            <b-badge variant="success">Sayfa: {{ pagination.current_page }} / {{ pagination.last_page }}</b-badge>
          </b-col>
          <b-col md="6">
            <b-pagination
                v-model="pagination.current_page"
                :total-rows="pagination.total"
                :per-page="pagination.per_page"
                limit="9"
                align="right"
                first-number
                last-number
                class="pagination-success"
                size="sm"
                @page-click="pageClick"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-overlay>
  </b-card>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Pagination',
  props: {
    pagination: Object,
    isShow: Boolean,
  },
  computed: {
    ...mapGetters('order', ['_filter']),
  },
  methods: {
    pageClick(bvEvent, page) {
      bvEvent.preventDefault()
      this.$emit('pageClick', page)
    },
  },
}
</script>

<style scoped>

</style>
