<template>
  <div>
    <TrendyolProductFilter/>
    <Pagination v-if="_pagination" :pagination="_pagination" @pageClick="pageClick" :isShow="isShow"/>
    <TrendyolProductList v-for="product in _products" :key="product.id" :product="product"/>
    <Pagination v-if="_pagination" :pagination="_pagination" @pageClick="pageClick" :isShow="isShow"/>
  </div>
</template>

<script>
import TrendyolProductFilter from '@/views/trendyol/product/TrendyolProductFilter.vue'
import {mapGetters} from 'vuex'
import TrendyolProductList from '@/views/trendyol/product/TrendyolProductList.vue'
import Pagination from "@/store/common/Pagination.vue";

export default {
  name: 'TrendyolProductHome',
  components: {
    Pagination,
    TrendyolProductFilter,
    TrendyolProductList,
  },
  computed: {
    ...mapGetters('trendyol', ['_products', '_pagination', '_filter']),
  },
  methods: {
    pageClick(page) {
      this.isShow = true
      this._filter.page = page
      this.$store.dispatch('trendyol/getProducts')
          .then(res => {
            this.isShow = false
          })
    },
  },
  data: () => ({
    isShow: false,
  }),
  created() {
    this.$store.dispatch('product/getProductBrands')
  }
}
</script>

<style scoped>

</style>
