<template>
  <a :href="'https://www.trendyol.com/w/w-w-p-'+product.ty_product_list.product_content_id+'?merchantId='+seller.merchantId"
     target="mpProduct"
  >
    <b-badge :variant="variant"
    > {{ seller.merchantName }} ({{ seller.merchantScore }}) {{ seller.discountedPrice }}₺
    </b-badge>
  </a>

</template>

<script>
export default {
  name: 'Seller',
  props: {
    index: {
      type: Number,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    seller: {
      type: Object,
      required: true,
    },
  },
  computed: {
    variant() {
      if (this.seller.isBuyBox && this.seller.merchantName === 'indirimyeri') return 'success'
      if (this.seller.merchantName === 'indirimyeri') return 'warning'
      return 'secondary'
    },
  },
}
</script>

<style scoped>

</style>
